<template>
  <div class="app">
    <form class="auth-form" @keypress.enter.prevent>
      <h1>Reset Password</h1>
      <div class="auth-site-name">afkadvantage.com</div>

      <div class="auth-input">
        <label for="inputNewPassword">New Password</label>
        <input v-model="password" type="password" placeholder="New Password" id="inputNewPassword" />
      </div>

      <div class="auth-input" style="margin-bottom: 50px">
        <label for="inputRepeatPassword">Repeat Password</label>
        <input v-model="passwordRepeat"
          type="password"
          placeholder="Repeat Password"
          id="inputRepeatPassword"
        />
      </div>

      <div class="auth-input">
        <button type="button" v-on:click="setPassword" class="button-primary-2" v-bind:disabled="isLoading">Change Password</button>
      </div>

      <div class="auth-switch-site">
        Remembered your password? <router-link to="/auth/login">Sign in</router-link>
      </div>
    </form>
  </div>
</template>

<script>
  import Api from '../../scripts/api';
  import Toast from '../../scripts/toast';
  
  export default {
      data() {
        return {
          password: '',
          passwordRepeat: '',
          isLoading: false
        }
      },
      methods: {
        setPassword: async function() {
          
          if(!Api.validation.validatePassword(this.password)) {
            return Toast.error('Password must be at least 6 characters.');
          }
          if(this.password != this.passwordRepeat) {
            return Toast.error('Passwords do not match.');
          }

          this.isLoading = true;
          try {

            var response = await Api.passwordSet(this.$route.params.key, this.password);
            if(response.data.success) {
              this.$router.push('/auth/login');
            } else {
              switch(response.data.reason) {
                case 0: Toast.error('The reset token has expired, please request a new password reset.'); break;
                case 1: Toast.error('Invalid password.'); break;
                case 2: Toast.error('The reset token has expired, please request a new password reset.'); break;
              }
              this.isLoading = false;
            }

          } catch (ex) {
            console.log(ex);
            Toast.error('Unexpected error occured, try again.')
            this.isLoading = false;
          }
        }
      }
  }
</script>

<style scoped>
@import "../../assets/css/auth.css";
</style>